<template>
  <div class="main">
    <router-link to="/shops/available">
      <a-button
        class="p-0 mb-3"
        type="link"
        icon="arrow-left"
        size="large">
        {{ $t('back') }} {{ $t('to') }} {{ $t('msg.platform.new.title') }}
      </a-button>
    </router-link>
    <div v-if="manifest">
      <h2>{{ manifest.name }}</h2>
      <p class="my-4">
        {{ manifest.description }}
      </p>
      <div class="card p-3">
        <div class="row card-body">
          <div class="col-xl-4 col-lg-12 mb-2">
            <a-avatar
              shape="square"
              :size="100"
              :src="`/resources/images/platforms/${type}.png`" />
          </div>
          <div class="col-xl-8 col-lg-12">
            <div v-html="tutorial" />
            <component
              :is="types[manifest.integration.type]"
              :manifest="manifest" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import lms from '@/api/lms'
import JTypeForm from './form'
import JTypeLink from './link'

const types = {
  FORM: JTypeForm,
  LINK: JTypeLink,
}

export default {
  data: function () {
    return {
      types,
      manifest: undefined,
      tutorial: undefined,
    }
  },
  computed: {
    type() {
      return this.$route.query.type
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      const r1 = lms.platform.fetchManifest(this.type)
      const r2 = lms.platform.fetchTutorial(this.type)
      axios.all([r1, r2])
        .then(axios.spread((...responses) => {
          this.manifest = responses[0].data
          this.tutorial = responses[1].data
        }))
    },
  },
}
</script>
