<template>
  <div class="mt-3">
    <a-form-model
      ref="form"
      :model="form">
      <j-input
        v-for="input in manifest.integration.input"
        v-model="form[input.code]"
        :key="input.code"
        :prop="input.code"
        :label="input.name"
        :placeholder="input.sample"
        :icon="input.icon"
        :rules="rules(input)" />
      <a-button
        class="mt-4"
        type="primary"
        size="large"
        @click="onSubmit">
        {{ $t('continue') }}
      </a-button>
      <a-button
        class="mt-4"
        type="link"
        size="large"
        v-if="manifest.academy_url">
        <a :href="manifest.academy_url" target="_blank">
        {{ $t('msg.shop.action.tutorial') }}
        </a>
      </a-button>
    </a-form-model>
  </div>
</template>

<script>
import v from '@/utils/validators'
import JInput from '@/views/shared/forms/input'
export default {
  name: 'IntLink',
  components: {
    JInput,
  },
  props: {
    manifest: Object,
  },
  data: function () {
    return {
      v,
      form: {},
    }
  },
  methods: {
    rules(input) {
      const rules = [v.required]
      switch (input.type) {
        case 'email':
          rules.push(v.email)
          break
        case 'number':
          rules.push(v.number)
          break
        case 'url':
          rules.push(v.url)
          break
      }
      return rules
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let integrationUrl = this.manifest.integration.url
          for (const key in this.form) {
            if (Object.prototype.hasOwnProperty.call(this.form, key)) {
              const value = this.form[key]
              const placeholder = new RegExp(`\\$\\{${key}\\}`, 'g')
              integrationUrl = integrationUrl.replace(placeholder, value)
            }
          }
          window.open(integrationUrl)
        }
      })
    },
  },
}
</script>
